import { Button, Image, Text, VStack, useToast } from "@chakra-ui/react";
import { BiPhoneCall } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import CenteredContentPage from "../layouts/CenteredContentPage";
import logo from "/logo192.png";
import { useEffect } from "react";
import { useApp } from "../hooks/useApp";
import { FaVideo } from "react-icons/fa";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle } = useApp();
  const toast = useToast();
  const auth = useAuth();

  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  const handleCallOption = () => {
    console.log("Call option clicked");
    try {
      navigate("/callable-users");
    } catch (error) {
      console.error("Failed to navigate:", error);
      toast({
        title: "Navigation Error",
        description: "Failed to navigate.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  
  const handleRecordVideoOption = () => {
    console.log("Call option clicked");
    try {
      navigate("/video-instructions");
    } catch (error) {
      console.error("Failed to navigate:", error);
      toast({
        title: "Navigation Error",
        description: "Failed to navigate.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleWorkflowOption = () => {
    navigate("/processes/list/jobs");
  };

  return (
    <CenteredContentPage>
      <VStack spacing={4} backgroundColor={'white'} borderRadius={20} p={4}>
        <Image src={logo} alt="GearIcon/NFKPLogo" mx="auto" h="75px" />
        <Text fontWeight="bolder" fontSize="lg">
          Welcome {auth.user?.name}
        </Text>
        <Button
          variant="primaryOutline"
          onClick={handleWorkflowOption}
          w="full"
          justifyContent="center"
          px="20px"
          py="24px"
        >
          Work Instructions
        </Button>
        <Button
          leftIcon={<BiPhoneCall />}
          variant="primary"
          onClick={handleCallOption}
          w="full"
          justifyContent="center"
          px="20px"
          py="24px"
        >
          Call
        </Button>
        <Button
          leftIcon={<FaVideo />}
          variant="primary"
          onClick={handleRecordVideoOption}
          w="full"
          justifyContent="center"
          px="20px"
          py="24px"
        >
          Video Instructions
        </Button>
      </VStack>
    </CenteredContentPage>
  );
};

export default Home;
