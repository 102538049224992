import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FloatingBottomBar from "../layouts/FloatingBottomBar";

const SliderButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button p={4} h={8} borderRadius={20} {...props}>
      {children}
    </Button>
  );
};

const Slider = <T, U>({
  items,
  endItems = [],
  renderItem,
  renderEndItem,
  buttonProps,
  nextTitle,
  previousTitle,
  nextTitleDesc,
  previousTitleDesc,
  containerStyle,
  onNext,
  onEnd,
  onBack,
  nextIcon,
}: {
  items: T[];
  endItems?: U[];
  renderItem: (item: T) => JSX.Element;
  renderEndItem?: (item: U) => JSX.Element;
  buttonProps?: ButtonProps;
  nextTitle?: string;
  previousTitle?: string;
  PreviousIcon?: JSX.Element;
  nextIcon?: JSX.Element;
  nextTitleDesc: string;
  previousTitleDesc: string;
  containerStyle?: BoxProps;
  onNext?: (step: number) => Promise<void>;
  onBack?: (step: number) => Promise<void>;
  onEnd?: (step: number) => Promise<void>;
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  console.log(
    "items?.length + endItems?.length",
    items?.length + endItems?.length
  );
  console.log("currentPage", currentPage);

  const handleNext = async () => {
    onNext && (await onNext(currentPage + 1));
    if (currentPage >= items?.length + endItems?.length - 1) {
      // next pressed on last page
      return onEnd && (await onEnd(currentPage + 1));
    }
    setCurrentPage((current) => current + 1);
  };

  const handlePrev = async () => {
    onBack && (await onBack(currentPage >= 1 ? currentPage - 1 : 0));
    setCurrentPage((current) => (current === 0 ? 0 : current - 1));
  };

  return (
    <VStack
      w={"full"}
      justifyContent={"center"}
      pos={"initial"}
      {...containerStyle}
    >
      {items.length - 1 >= currentPage && renderItem(items[currentPage])}
      {items.length - 1 < currentPage &&
        endItems?.length &&
        renderEndItem &&
        renderEndItem(endItems[currentPage - items?.length])}
      <FloatingBottomBar>
        <HStack>
          <SliderButton
            isDisabled={currentPage === 0}
            onClick={handlePrev}
            title={previousTitleDesc || "previous"}
            aria-label={previousTitleDesc || "previous"}
            {...buttonProps}
          >
            {previousTitle || "Previous"}
          </SliderButton>{" "}
          <SliderButton
            isDisabled={
              endItems.length
                ? false
                : currentPage >= items.length + endItems.length - 1
            }
            onClick={handleNext}
            title={nextTitleDesc || "next"}
            aria-label={nextTitleDesc || "next"}
            {...buttonProps}
          >
            {nextTitle || "Next"}
          </SliderButton>
        </HStack>
      </FloatingBottomBar>
    </VStack>
  );
};

export default Slider;
