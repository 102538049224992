import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import "@tldraw/tldraw/tldraw.css";
import { useCallback, useEffect, useState } from "react";
import { RiTeamLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import downArrow from "../assets/down-arrow.png";
import repetitiveImg from "../assets/repetitive.png";
import rightArrow from "../assets/right-arrow.png";
import DataItemsLazy from "../components/DataItemsLazy";
import { ProcessRelevance } from "../enums/ProcessRelevance";
import { ProcessStatus } from "../enums/ProcessStatus";
import { useProcesses } from "../hooks/calls/useProcesses";
import { useApp } from "../hooks/useApp";
import { useAuth } from "../hooks/useAuth";
import CenteredContentPage from "../layouts/CenteredContentPage";
import { ProcessTeamUser } from "../models/ProcessTeamUser";
import { GeneralTableFilters } from "../types/AppTypes";
import COLORS from "../utils/colors";

const Instruction: React.FC<{ item: ProcessTeamUser; index: number }> = ({
  item,
  index,
}) => {
  const navigate = useNavigate();
  const [showComments, setShowComments] = useState(false);
  const lastExecution =
    item.executionHistory?.length &&
    item.executionHistory[item.executionHistory?.length - 1];
  if (!item.process) {
    return null;
  }

  return (
    <VStack
      w={"full"}
      key={item.id}
      title={item.process.name}
      borderRadius={8}
      gap={0}
      backgroundColor={
        item.status === ProcessStatus.TODO ? COLORS.accent : COLORS.gray
      }
      alignItems={"flex-start"}
    >
      <HStack
        flex={10}
        w={"full"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
      >
        <HStack
          title={`Select ${index + 1}`}
          onClick={() => navigate(`/processes/execute/${item.process._id}`)}
        >
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            backgroundColor={
              item.status === ProcessStatus.DONE ? "gray" : "white"
            }
            // borderRadius={100}
            // width={10}
            // height={10}
          >
            {/* <Text color={"black"} noOfLines={1}>
              {index + 1}
            </Text> */}
          </Box>
          <Text
            color={item.status === ProcessStatus.DONE ? "gray" : "white"}
            noOfLines={1}
            textDecor={item.status === ProcessStatus.DONE ? "line-through" : ""}
          >
            {item.process.name}
          </Text>
        </HStack>
        <HStack>
          {item.team && <RiTeamLine color="#17365E" size={20} />}
          {item.frequency && <Image w={5} src={repetitiveImg} />}
          <Text fontSize={"sm"} color={"gray"}>
            {ProcessRelevance.URGENT === item.relevance && (
              <Text fontSize={"lg"} color={COLORS.primary}>
                Urgent
              </Text>
            )}
            {ProcessRelevance.HIGH === item.relevance && (
              <Text fontSize={"lg"} color={COLORS.primary}>
                High
              </Text>
            )}
            {ProcessRelevance.MIDDLE === item.relevance && (
              <Text fontSize={"lg"} color={COLORS.primary}>
                Medium
              </Text>
            )}
          </Text>
          {!showComments && (
            <Image
              onClick={() => setShowComments(!showComments)}
              src={rightArrow}
            />
          )}
          {showComments && (
            <Image
              onClick={() => setShowComments(!showComments)}
              src={downArrow}
            />
          )}
        </HStack>
      </HStack>
      {showComments && (
        <VStack alignItems={"flex-start"} spacing={0} pl={8}>
          <Text color={"white"}>
            {lastExecution && lastExecution.completedSteps}/
            {item.process.instructions?.length} steps completed
          </Text>
          <Text color={"white"}>Assgined by {item.assignedBy.name}</Text>
          <Text color={"white"}>notes: {item.comments}</Text>
        </VStack>
      )}
    </VStack>
  );
};

const ProcessWorkInstructions = () => {
  // const navigate = useNavigate();
  const { socket } = useAuth();
  const { setTitle } = useApp();
  // const { type } = useParams();
  const { data, isLoading, load } = useProcesses(true);
  // TODO: Apply later filters if needed.
  const [filters, setFilters] = useState<GeneralTableFilters>({
    pageIndex: 0,
    pageSize: 5,
    sorting: [{ id: "status", desc: true }],
    text: "",
  });

  useEffect(() => {
    setTitle("Work Instructions");
  }, [setTitle]);

  const reloadList = useCallback(async () => {
    load(filters);
  }, [load, filters]);

  useEffect(() => {
    if (socket) {
      socket.on("assigned_work_instruction", reloadList);
    }
    return () => {
      socket?.off("assigned_work_instruction", reloadList);
    };
  }, [socket, reloadList]);

  const preProcessFilter = useCallback((filter: GeneralTableFilters) => {
    return {
      ...filter,
    };
  }, []);

  // const sortByRelevance = (items: ProcessTeamUser[]) => {
  //   return items.sort((a, b) => {
  //     const relevanceOrder = {
  //       [ProcessRelevance.URGENT]: 3,
  //       [ProcessRelevance.HIGH]: 2,
  //       [ProcessRelevance.MIDDLE]: 1,
  //       [ProcessRelevance.LOW]: 0,
  //     };
  //     return relevanceOrder[b.relevance] - relevanceOrder[a.relevance];
  //   });
  // };

  const renderItem = (item: ProcessTeamUser, index: number) => {
    return <Instruction item={item} index={index} />;
  };

  return (
    <CenteredContentPage>
      <VStack w="full" alignItems="center" spacing={4}>
        <VStack
          alignItems="stretch"
          spacing={0}
          w="full"
          fontWeight="bold"
          margin="0,auto"
        >
          <DataItemsLazy
            renderRefresh
            data={data?.data || []}
            filter={filters}
            totalItems={data?.totalItems || 0}
            fetchData={load}
            isLoading={isLoading}
            pageCount={data?.pageCount || 0}
            preProcessFilter={preProcessFilter}
            renderItem={renderItem}
          />
        </VStack>
        {!isLoading && !data?.data?.length && (
          <Text color='white' fontSize='large'>No processes available</Text>
        )}
      </VStack>
    </CenteredContentPage>
  );
};

export default ProcessWorkInstructions;
