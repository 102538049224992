import {
  Box,
  Button,
  Heading,
  Input,
  Image,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { QrScanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import logo from "/logo192.png"

const LoginPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const from = location.state?.from?.pathname || "/dashboard";

  const onSubmit = async () => {
    try {
      await auth.signInWithCredentials(username, password);
      // If 'from' is undefined, make sure to define it or replace it with the actual path you want to redirect to
      navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
      toast({
        title: "Login failed",
        description: "Invalid username or password",
        status: "error",
      });
    }
  };

  const handleJwtQRCode = async (hash: string) => {
    try {
      await auth.signin(hash);
      navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const msg = error.response?.data?.message || error.message;
        // console.log("msg", msg);
      }
      toast({ title: "Cannot login", status: "error" });
    }
  };

  useEffect(() => {
    if (auth.user) {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  return (
    <Box
      width="100%"
      height={"calc(var(--vh, 1vh) * 100)"}
      overflow="hidden"
      mx="auto"
      bg="linear-gradient(-45deg,#17375F, #00A89C)"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box align="center" justify="center">
        <Stack mx="auto" maxW="lg" px={6}>
          <Box
            rounded="lg"
            bg={useColorModeValue("white", "gray.700")}
            boxShadow="lg"
            p={2}
          >
            <Stack>
              <Stack>
                <Image
                  src={logo}
                  alt="GearIcon/NFKPLogo"
                  mx="auto"
                  h="75px"
                />
                <Stack align={"center"}>
                  <Heading fontSize={"md"} align="center">
                    Buhler Vision
                  </Heading>
                </Stack>
                <FormControl spacing={4}>
                  <Input
                    fontSize={"sm"}
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input
                    marginTop={2}
                    fontSize={"sm"}
                    id="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    marginTop={2}
                    variant="primaryOutline"
                    onHover={{
                      bg: "orange.500",
                    }}
                    isLoading={auth.isLoading}
                    fontSize={"md"}
                    onClick={onSubmit}
                    type="button"
                  >
                    Sign In
                  </Button>
                </FormControl>

                <Button
                  variant={"primary"}
                  _hover={{
                    bg: "white",
                    color: "orange.300",
                    border: "solid, .5px",
                  }}
                  fontSize={"md"}
                  isLoading={auth.isLoading}
                  onClick={() => setShowQRScanner(true)}
                  type="button"
                >
                  Sign in With QR code
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
      {showQRScanner && (
        <Modal
          isOpen={showQRScanner}
          motionPreset="slideInBottom"
          size="xs"
          orientation="vertical"
          onClose={() => setShowQRScanner(false)}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent margin={"auto"}>
            <ModalHeader p={0} pl={2} fontSize={"sm"}>
              Scan QR Code
            </ModalHeader>
            <ModalCloseButton top={0} />
            <ModalBody borderRadius={20}>
              <QrScanner
                constraints={{ facingMode: "rear" }}
                onDecode={(result) => {
                  console.log("result", result);
                  if (result) {
                    handleJwtQRCode(result);
                    setShowQRScanner(false);
                  } else {
                    console.log("no result from qr code");
                  }
                }}
                onError={(error) => console.log(error?.message)}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default LoginPage;
