import {
  Box,
  Button,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { useEffect } from "react";
import { IoReload } from "react-icons/io5";
import { GeneralTableFilters } from "../types/AppTypes";

interface DataItemsLazyProps<T> {
  data: T[];
  pageCount: number;
  totalItems: number;
  fetchData: (filter: GeneralTableFilters) => any;
  isLoading: boolean;
  renderRefresh?: boolean;
  filter: GeneralTableFilters;
  preProcessFilter?: (filter: GeneralTableFilters) => Record<string, any>;
  renderItem: (item: T, index: number) => JSX.Element;
}

const DataItemsLazy = <T,>({
  data,
  pageCount,
  fetchData,
  totalItems,
  isLoading,
  renderRefresh = false,
  filter,
  preProcessFilter, // MUST usecallback!!!!
  renderItem,
}: DataItemsLazyProps<T>): JSX.Element => {
  const [sorting, setSorting] = React.useState<SortingState>(filter.sorting);
  const table = useReactTable({
    columns: [],
    data,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    // onSortingChange: (state: Updater<SortingState>) => setSorting(state),
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: filter.pageSize,
      },
    },
    state: {
      sorting,
    },
  });

  const pageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  // const sorting = table.getState().sorting;

  const search = React.useCallback(async () => {
    console.log(
      "pageIndex, pageSize",
      pageIndex,
      pageSize,
      sorting,
      filter?.text
    );
    fetchData({
      ...(preProcessFilter ? preProcessFilter(filter) : filter),
      pageIndex: pageIndex,
      pageSize: pageSize,
      sorting: sorting,
      text: filter.text,
    });
  }, [fetchData, pageIndex, pageSize, sorting, preProcessFilter, filter]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    table.setPageIndex(0); // if filter changes go to page 0 because sometimes the criteria has less values than current page
  }, [JSON.stringify(filter), table]);

  return (
    <Box w={"full"}>
      {isLoading ? (
        <Stack w="full" alignItems="center">
          <Spinner />
        </Stack>
      ) : (
        <VStack w={"full"}>
          <HStack w={"full"} justifyContent={"space-between"}>
            <VStack>
              {/* <Button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button> */}
              <Button
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                variant={"ghost"}
                disabled={!table.getCanPreviousPage()}
                isDisabled={!table.getCanPreviousPage()}
                color={"white"}
              >
                {"<"}
              </Button>
            </VStack>
            <VStack width={"full"} flexWrap={"wrap"} justifyContent={"center"}>
              <HStack right={0} alignSelf={"flex-end"}>
                {totalItems && (
                  <Text color={"white"} fontSize={"xs"}>
                    {totalItems} Total Items
                  </Text>
                )}
                {renderRefresh && (
                  <IconButton
                    icon={<IoReload />}
                    onClick={search}
                    size={"sm"}
                    aria-label="Reload"
                  />
                )}
              </HStack>
              {table.getRowModel().rows.map((row, i) => {
                const data = row.original;
                return (
                  <Box key={i} width={"100%"}>
                    {renderItem(data, i)}
                  </Box>
                );
              })}
              {pageIndex + 1 && pageCount && (
                <Text
                  fontSize={"xs"}
                  color={"white"}
                  right={0}
                  alignSelf={"flex-end"}
                >
                  {pageIndex + 1} / {pageCount}
                </Text>
              )}
            </VStack>
            <VStack>
              <Button
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                isDisabled={!table.getCanNextPage()}
                variant={"ghost"}
                color={"white"}
              >
                {">"}
              </Button>
              {/* <Button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button> */}
            </VStack>
          </HStack>
          <HStack mt={4} justifyContent={"flex-end"}>
            <Box>
              {/* <Text color={'white'}>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()}
                </strong>
              </Text> */}
            </Box>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default DataItemsLazy;
