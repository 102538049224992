import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import "@tldraw/tldraw/tldraw.css";
import { useCallback, useEffect, useState } from "react";
import { MdZoomIn } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import YouTube from "react-youtube";
import nextIconImg from "../assets/next-icon.png";
import Slider from "../components/Slider";
import { useProcesses } from "../hooks/calls/useProcesses";
import { useApp } from "../hooks/useApp";
import CenteredContentPage from "../layouts/CenteredContentPage";
import FloatingBottomBar from "../layouts/FloatingBottomBar";
import { File, Instruction, ProcessTeamUser } from "../models/ProcessTeamUser";
import COLORS from "../utils/colors";
import { CONFIG } from "../utils/constants";

interface EndItem {
  desc: string;
}
const endItems: EndItem[] = [
  { desc: "Congratulations! You've completed all the instructions." },
];

const PreviewFile: React.FC<{ file: File }> = ({ file }) => {
  return ["qt", "mp4"].includes(file.fileType) ? (
    <video controls>
      <source src={file.url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : file.fileType === "webm" ? (
    <video controls>
      <source src={file.url} type="video/webm" />
      Your browser does not support the video tag.
    </video>
  ) : ["png", "jpeg", "jpg"].includes(file.fileType) ? (
    <Box>
      <Image src={file.url} />
    </Box>
  ) : (
    ["youtube"].includes(file.fileType) && (
      <Box w={"full"} h={"full"}>
        <YouTube
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "lg",
          }}
          opts={{ width: "100%", height: "100%" }}
          videoId={file.key || ""}
        />
      </Box>
    )
  );
};

const RenderFile = ({ file }: { file: File }) => {
  const size = 24;
  const [zoomIn, setZoomIn] = useState<boolean>(false);
  const onCloseModal = () => setZoomIn(false);
  console.log("file.fileType", file.fileType);
  return (
    <Box pos={"relative"}>
      <Box
        pos="absolute"
        left={"50%"}
        transform="translate(-50%, -50%)"
        top="{calc(var(--vh, 1vh) * 10)}"
      >
        <Button
          size={"sm"}
          backgroundColor={"white"}
          borderRadius={20}
          onClick={() => setZoomIn(true)}
          aria-label="Zoom in"
        >
          <MdZoomIn title="Zoom In" size={size} />
          <Text>Zoom in</Text>
        </Button>
      </Box>
      <PreviewFile file={file} />
      <Box>
        {zoomIn && (
          <Modal size={"full"} isOpen={true} onClose={onCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton color={"black"} backgroundColor={"white"} />
              <ModalBody
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <CenteredContentPage boxProps={{ h: "100%" }} force>
                  <PreviewFile file={file} />
                </CenteredContentPage>
              </ModalBody>
              <FloatingBottomBar style={{ right: 0 }}>
                <Button title="close" onClick={onCloseModal}>
                  Close
                </Button>
              </FloatingBottomBar>
            </ModalContent>
          </Modal>
        )}
      </Box>
    </Box>
  );
};

const renderInstruction = (item: Instruction) => {
  return (
    <HStack
      key={item._id}
      backgroundColor={COLORS.accent}
      borderRadius={6}
      width="full"
      height="{calc(var(--vh, 1vh) * 80)}"
      padding={6}
    >
      <VStack gap={4} flex={1}>
        <Text fontWeight={"bolder"} fontSize="3xl" color={"white"}>
          {item.title}
        </Text>
        <Text color={"white"} fontSize={item.files ? 'xl' : '2xl'}>
          {item.description}
        </Text>
      </VStack>
      {item.files && item.files.length > 0 && (
        <Box flex={1}>
          <Slider
            nextTitle=">"
            previousTitle="<"
            items={item.files}
            renderItem={(file) => <RenderFile file={file} />}
            nextTitleDesc="Upcoming file"
            previousTitleDesc="Previous File"
            containerStyle={{ pos: "relative" }}
          />
        </Box>
      )}
    </HStack>
  );
};

const renderEndItem = (item: EndItem) => {
  return (
    <HStack>
      <Box flex={1}>
        <Text color={"white"}>{item.desc}</Text>
      </Box>
    </HStack>
  );
};

const ExecuteInstruction = () => {
  const { id } = useParams();
  const toast = useToast();
  const { setTitle } = useApp();
  const navigate = useNavigate();
  const [processUser, setProcessUser] = useState<ProcessTeamUser>();
  // const [showDownloadModal, setShowDownloadModal] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const { loadById, loadByIdAndExecute, handleOnLocalSave, processGoNext } =
    useProcesses(true);

  const onLocalSave = useCallback(async () => {
    if (processUser && id) {
      try {
        setSaving(true);
        await handleOnLocalSave(processUser.process);
        loadById(id);
        // setShowDownloadModal(false);
        console.log("downloaded filed");

        // toast({ title: "Files were downloaded!", status: "success" });
      } catch (error) {
        console.log("error while downloading files");
        // toast({ title: "Error while downloading files.", status: "error" });
        console.error(error);
      } finally {
        setSaving(false);
      }
    }
  }, [handleOnLocalSave, id, loadById, processUser]);

  useEffect(() => {
    if (id) {
      loadByIdAndExecute(id)
        .then((d) => setProcessUser(d))
        .catch(() => {
          toast({ title: "Cannot execute instruction", status: "error" });
          navigate("/processes/list/jobs", { replace: true });
        });
    }
  }, [id, loadByIdAndExecute, toast, navigate]);

  const handleOnEnd = async (step: number) => {
    if (id) {
      try {
        await processGoNext(
          id,
          Math.min(processUser?.process?.instructions?.length || 0, step)
        ); // increase index to natural index
      } catch (error) {
        console.error("error", error);
      } finally {
        // if user is offline this will fail
        navigate("/processes/list/jobs", { replace: true });
      }
    }
  };

  const handleOnBack = async (step: number) => {
    setTitle(
      `${step + 1}/${processUser?.process.instructions?.length} steps completed`
    );
  };

  const handleOnNext = async (step: number) => {
    if (step + 1 <= (processUser?.process?.instructions?.length || 0)) {
      setTitle(
        `${step + 1}/${processUser?.process.instructions?.length
        } steps completed`
      );
    } else {
      setTitle("Completed!");
    }
    id &&
      processGoNext(
        id,
        Math.min(processUser?.process?.instructions?.length || 0, step)
      ); // increase index to natural index
  };

  // const handleExit = () => {
  //   navigate("/processes/list/jobs", { replace: true });
  // };

  useEffect(() => {
    if (processUser) {
      setTitle(
        `${1}/${processUser?.process.instructions?.length} steps completed`
      );
    }
  }, [setTitle, processUser]);

  useEffect(() => {
    onLocalSave();
  }, [processUser, id, onLocalSave]);

  return (
    <Box w={"full"}>
      <CenteredContentPage
        force
        boxProps={{ ml: 0, mr: 0, p: 0, mt: 0, pl: 0 }}
      >
        <Box w="full" alignItems="center">
          {/*<Button
            onClick={handleExit}
            pos={"absolute"}
            top={`calc(var(--vh, 1vh) * ${CONFIG.NAVBAR_HEIGHT} + 10px)`}
            title="exit"
            size={"xs"}
            colorScheme={"red"}
            ml='2'
            w='50px'
          >
            Exit
          </Button>*/}
          <Box w="100%" alignItems="center" justifyContent="center">
            <VStack spacing={0} padding={2}>
              {processUser?.process && (
                <Slider
                  items={processUser?.process?.instructions}
                  renderItem={renderInstruction}
                  nextTitle="Next"
                  previousTitle="Back"
                  nextTitleDesc="Next"
                  previousTitleDesc="Back"
                  onNext={handleOnNext}
                  onEnd={handleOnEnd}
                  onBack={handleOnBack}
                  endItems={endItems}
                  renderEndItem={renderEndItem}
                  nextIcon={<Image src={nextIconImg} />}
                />
              )}
              {!processUser && <Spinner />}
            </VStack>
          </Box>
        </Box>
      </CenteredContentPage>
    </Box>
  );
};

export default ExecuteInstruction;
