import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Image,
  Text
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
// import icon from "../../public/favicon.png";
import { useState } from "react";
import { FiHome } from "react-icons/fi";
import icon from "../../public/logo192.png";
import useNavigatorOnLine from "../hooks/calls/useNetworkStatus";
import { useApp } from "../hooks/useApp";
import ConfigOptions from "../pages/ConfigOptions";
import COLORS from "../utils/colors";
import { CONFIG } from "../utils/constants";

const Layout = () => {
  const auth = useAuth();
  const location = useLocation();
  const isVideoCall = ["/direct-call", "/appointment-call"].includes(
    location.pathname
  );

  return (
    <div>
      {auth.user && !isVideoCall && <Header isVideoCall={isVideoCall} />}
      <Container
        maxW="full"
        padding={0}
        backgroundColor={COLORS.primary}
        minH={`calc(var(--vh, 1vh) * ${
          CONFIG.MAIN_HEIGHT - CONFIG.NAVBAR_HEIGHT
        })`}
      >
        <Outlet />
      </Container>
    </div>
  );
};

function Header({ isVideoCall }: { isVideoCall: boolean }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { title } = useApp();
  const isOnline = useNavigatorOnLine();
  const [showOptions, setShowOptions] = useState(false);

  const failedRequests: [{ method: string; url: string }] =
    JSON.parse(localStorage.getItem("failedRequests") || "[]") || [];

  const onLogout = () => {
    auth.signout();
  };
  const goHome = () => navigate("/dashboard", { replace: true });
  return (
    <HStack
      // bgGradient={"linear(to-r, #17375F, #00A89C)"}
      backgroundColor={COLORS.secondary}
      justifyContent={"space-between"}
      height={`calc(var(--vh, 1vh) * ${CONFIG.NAVBAR_HEIGHT})`}
    >
      <Image src={icon} height={"100%"} />

      <Text
        fontSize={"xl"}
        color={COLORS.primary}
        fontWeight={"bold"}
        marginStart={"auto"}
        marginEnd={"auto"}
      >
        {title}
      </Text>
      <HStack alignItems={"center"}>
        {!isOnline && (
          <HStack mr={2}>
            <Box
              borderRadius={20}
              width={2}
              height={2}
              backgroundColor={"red"}
            />
            <Text color={COLORS.primary} fontWeight={"bold"}>
              Offline
            </Text>
          </HStack>
        )}
        <IconButton
          onClick={goHome}
          size="md"
          icon={<FiHome />}
          variant="ghost"
          aria-label="Home"
          title="Home"
        />
        <Button
          title="Options"
          onClick={() => setShowOptions(true)}
          variant={"ghost"}
        >
          <HStack alignItems={"flex-end"} gap={0}>
            {failedRequests?.length > 0 && (
              <Box w={1} h={1} bg={"red"} borderRadius={22} />
            )}
            <BsThreeDotsVertical size={22} />
          </HStack>
        </Button>
      </HStack>
      {/* <HStack justifyContent={"center"}>
        <Text fontWeight={"bold"} fontSize={"sm"} color={"#254367"}>
          {auth.user?.name} ({auth.user?.ubimaxId})
        </Text>
        <IconButton
          aria-label="Logout"
          _hover={{ color: "blue", backgroundColor: "white" }}
          size={"sm"}
          height={4}
          width={4}
          color={"#254367"}
          variant={"ghost"}
          icon={<AiOutlineLogout />}
          onClick={onLogout}
        />
      </HStack> */}
      {showOptions && <ConfigOptions onClose={() => setShowOptions(false)} />}
    </HStack>
  );
}

export default Layout;
