import { Box, BoxProps } from "@chakra-ui/react";

const FloatingBottomBar = ({
  children,
  style,
}: {
  children: JSX.Element;
  style?: BoxProps;
}) => {
  return (
    <Box
      pos={"absolute"}
      bottom={0}
      // backgroundColor={"#ffffff54"}
      borderRadius={12}
      padding={1}
      {...style}
    >
      {children}
    </Box>
  );
};

export default FloatingBottomBar;
